import React from "react";
import { customStyle } from "../../../utils/customStyles/customStyles";
import { UserMenu, VendorSelect, NavigationLinks } from "./components";
import logo_sbl from "../../../assets/logo_sbl.png";
import logo_sbl_min from "../../../assets/SBL.png";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Box, Drawer, List, ListItem } from "@mui/material";
import { useGetCurrentUser } from "../../../hooks";
import { useMenuZustand } from "./hooks/useMenuZustand";
import { useHistory } from "react-router";
import LanguageSelector from "../../languageSelect/languageSelect";

const minMaxIcon = {
  alignSelf: "right",
  color: customStyle.textColor,
  opacity: 0.5,
  height: "50px",
  width: "50px",
  marginRight: "10px",
};

export const Navigation: React.FC = () => {
  const { currentUser } = useGetCurrentUser();
  const { open, changeMenuState } = useMenuZustand();
  const history = useHistory();

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        width: open ? customStyle.menuWidth : customStyle.minMenuWidth,
        ".MuiDrawer-paper": {
          background: customStyle.mainColor,
          width: open ? customStyle.menuWidth : customStyle.minMenuWidth,
          borderRight: "none",
        },
      }}
      data-testid="MUIDrawer"
    >
      <List key="menuList" data-testid="menuList">
        <ListItem
          disableGutters={!open}
          sx={{
            backgroundColor: customStyle.contentColor,
            marginTop: "-8px", //we need it because of default material margins
            height: customStyle.headerFooterHeight,
            justifyContent: "center",
            cursor: "pointer",
          }}
          key="logo_sbl"
          data-testid="logo_sbl"
          onClick={() => {
            history.push("home");
          }}
        >
          <img
            className={open ? "logo_sblImg" : "min_logo_sbl"}
            src={open ? logo_sbl : logo_sbl_min}
            alt={"logo_sbl"}
          />
        </ListItem>
        <NavigationLinks />
        {!open ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Box
              onClick={() => {
                changeMenuState(!open);
              }}
            >
              <KeyboardDoubleArrowRightIcon sx={{ ...minMaxIcon }} />
            </Box>
          </Box>
        ) : null}
      </List>

      {open && (
        <Box
          sx={{
            position: "fixed",
            bottom: "-8px", //we need it because of default margins of Drawer
          }}
        >
          {currentUser && currentUser.roles[0] === "SUPER_ADMIN" && (
            <VendorSelect />
          )}
          <Box sx={{ display: "grid", gridTemplateColumns: "60% 30%" }}>
            <UserMenu />
            <Box
              onClick={() => {
                changeMenuState(!open);
              }}
            >
              {open ? (
                <KeyboardDoubleArrowLeftIcon sx={{ ...minMaxIcon }} />
              ) : (
                <KeyboardDoubleArrowRightIcon sx={{ ...minMaxIcon }} />
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Drawer>
  );
};
